<template>
  <div>
    <vs-row>
      <vs-col class="text-right">
        <h1>
          <i class="fas fa-phone ml-2"></i>
          معلومات التواصل
        </h1>
        <hr />
      </vs-col>
    </vs-row>
    <vs-row
      vs-align="center"
      vs-type="flex"
      vs-justify="space-around"
      vs-w="12"
    >
      <vs-col
        class="mt-3"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="5"
        vs-xs="12"
      >
        <vs-input
          :danger="errors.name != ''"
          :danger-text="errors.name"
          @blur="clearerrors('name')"
          label="الأسم الكامل*"
          v-model="name"
          class="w-100"
        />
      </vs-col>
      <vs-col
        class="mt-3"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="5"
        vs-xs="12"
      >
        <vs-input
          :danger="errors.email != ''"
          :danger-text="errors.email"
          @blur="clearerrors('email')"
          label="الإيميل*"
          v-model="email"
          class="w-100"
        />
      </vs-col>
      <vs-col
        class="mt-3"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="5"
        vs-xs="12"
      >
        <div
          class="vs-component vs-con-input-label vs-input vs-input-primary w-100"
          :class="errors.phone != '' ? 'input-icon-validate-danger' : ''"
        >
          <label for class="vs-input--label">رقم الهاتف*</label>
          <vue-tel-input
            label="رقم الهاتف*"
            v-model="phone"
            inputClasses="vs-inputx vs-input--input normal ob-tel-dir-fix"
            placeholder
            :preferredCountries="['SA']"
            mode="international"
            @blur="clearerrors('phone')"
          ></vue-tel-input>
          <span>
            <div
              class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
              style="height: 21px;"
              v-if="errors.phone"
            >
              <span class="span-text-validation">{{ errors.phone }}</span>
            </div>
          </span>
        </div>
      </vs-col>
      <vs-col
        class="mt-3"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="5"
        vs-xs="12"
      >
        <vs-input
          :danger="errors.deliveryAddress != ''"
          :danger-text="errors.deliveryAddress"
          label="العنوان*"
          v-model="deliveryAddress"
          @blur="clearerrors('deliveryAddress')"
          class="w-100"
        />
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    errors() {
      return this.$store.state.error.thirdTab;
    },
    name: {
      get() {
        return this.$store.state.deliveryInfo.name;
      },
      set(value) {
        this.$store.commit("SET_DELIVERY_INFO_INDEX", {
          index: "name",
          value,
        });
        this.clearerrors("name");
      },
    },
    email: {
      get() {
        return this.$store.state.deliveryInfo.email;
      },
      set(value) {
        this.$store.commit("SET_DELIVERY_INFO_INDEX", {
          index: "email",
          value,
        });
        this.clearerrors("email");
      },
    },
    phone: {
      get() {
        return this.$store.state.deliveryInfo.phone;
      },
      set(value) {
        this.$store.commit("SET_DELIVERY_INFO_INDEX", {
          index: "phone",
          value,
        });
        this.clearerrors("phone");
      },
    },
    deliveryAddress: {
      get() {
        return this.$store.state.deliveryInfo.deliveryAddress;
      },
      set(value) {
        this.$store.commit("SET_DELIVERY_INFO_INDEX", {
          index: "deliveryAddress",
          value,
        });
        this.clearerrors("deliveryAddress");
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    clearerrors(index) {
      this.$store.commit("CLEARE_THIRD_TAB_ERROR", {
        index,
      });
    },
  },
};
</script>

<style>
.ob-tel-dir-fix {
  direction: ltr;
  text-align: right;
}
</style>
