<template>
  <div>
    <vs-row vs-align="center" vs-justify="space-around" vs-w="12">
      <vs-col
        type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="5"
        vs-xs="12"
      >
        <vs-card actionable class="cardx">
          <div slot="media" class="mt-5">
            <img src="@/assets/images/lunch.png" class="ob-img" />
          </div>
          <div>
            <h1 class="text-center">الحزمة الأولى</h1>
            <h3>وجبة غداء</h3>
          </div>
          <div slot="footer">
            <vs-row
              vs-align="center"
              vs-type="flex"
              vs-justify="space-around"
              vs-w="12"
            >
              <vs-button
                :color="buttonColor(1)"
                type="filled"
                @click="setPackage(1)"
              >
                <i class="fas fa-check-circle"></i>
                {{ selectedPackage != 1 ? "أختر الأن" : "تم الإختيار" }}
              </vs-button>
            </vs-row>
          </div>
        </vs-card>
      </vs-col>
      <vs-col
        type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="5"
        vs-xs="12"
      >
        <vs-card actionable class="cardx">
          <div slot="media" class="mt-5">
            <img src="@/assets/images/all-meals.png" class="ob-img" />
          </div>
          <div>
            <h1 class="text-center">الحزمة الثانية</h1>
            <h3 class="text-center">جميع الوجبات</h3>
          </div>
          <div slot="footer">
            <vs-row
              vs-align="center"
              vs-type="flex"
              vs-justify="space-around"
              vs-w="12"
            >
              <vs-button
                :color="buttonColor(2)"
                type="filled"
                @click="setPackage(2)"
                disabled
              >
                <!-- <i class="fas fa-check-circle"></i> -->
                قريبا
              </vs-button>
            </vs-row>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>

    <vs-row
      vs-align="center"
      vs-type="flex"
      vs-justify="space-around"
      vs-w="12"
    >
      <vs-col
        class="mt-3"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
        vs-xs="12"
      >
        <div
          class="vs-component vs-con-input-label vs-input w-100 vs-input-primary"
          :class="error.startDate ? 'input-icon-validate-danger' : ''"
        >
          <label for="" class="vs-input--label">تاريخ البداية*</label>
          <date-picker
            v-model="date"
            valueType="date"
            input-class="vs-inputx vs-input--input normal pr-30px has-error"
            class="w-100"
            prefix-class="xmx"
            :disabled-date="disabledBefore48HoursFromNow"
            :default-value="dateAfter48Hours"
          ></date-picker>
          <span v-if="error.startDate"
            ><div
              class="con-text-validation span-text-validation-danger v-enter-to"
              style="height: 22px;"
            >
              <span class="span-text-validation">
                {{ error.startDate }}
              </span>
            </div></span
          >
        </div>
      </vs-col>
      <vs-col
        class="mt-3"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
        vs-xs="12"
      >
        <div
          class="vs-component vs-con-input-label vs-input w-100 vs-input-primary"
          :class="error.duration ? 'input-icon-validate-danger' : ''"
        >
          <label for="" class="vs-input--label">مدة الإشتراك*</label>
          <v-select
            :options="durationOptions"
            :reduce="duration => duration.value"
            label="text"
            class="w-100 ob-select"
            :class="error.duration ? 'has-error' : ''"
            v-model="duration"
            @blur="clearErrors('duration')"
            dir="rtl"
          >
          </v-select>
          <span v-if="error.duration"
            ><div
              class="con-text-validation span-text-validation-danger v-enter-to"
              style="height: 22px;"
            >
              <span class="span-text-validation">
                {{ error.duration }}
              </span>
            </div></span
          >
        </div>
        <!-- <vs-select
          :danger="error.duration != ''"
          :danger-text="error.duration"
          @blur="clearErrors('duration')"
          class="selectExample w-100"
          label="مدة الإشتراك*"
          v-model="duration"
        >
          <vs-select-item value="month" text="شهر" />
          <vs-select-item value="week" text="أسبوع" />
        </vs-select> -->
      </vs-col>
      <vs-col
        class="mt-3"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
        vs-xs="12"
      >
        <div
          class="vs-component vs-con-input-label vs-input w-100 vs-input-primary"
          :class="
            error.numberOfDeliveryDays ? 'input-icon-validate-danger' : ''
          "
        >
          <label for="" class="vs-input--label"
            >عدد أيام التسليم من كل أسبوع*</label
          >
          <v-select
            :options="numberOfDeliveryDaysOptions"
            :reduce="numberOfDeliveryDays => numberOfDeliveryDays.value"
            label="text"
            :class="error.numberOfDeliveryDays ? 'has-error' : ''"
            class="w-100 ob-select"
            v-model="numberOfDeliveryDays"
            @blur="clearErrors('numberOfDeliveryDays')"
            dir="rtl"
          >
          </v-select>
          <span v-if="error.numberOfDeliveryDays"
            ><div
              class="con-text-validation span-text-validation-danger v-enter-to"
              style="height: 22px;"
            >
              <span class="span-text-validation">
                {{ error.numberOfDeliveryDays }}
              </span>
            </div></span
          >
        </div>
        <!-- <vs-select
          :danger="error.numberOfDeliveryDays != ''"
          :danger-text="error.numberOfDeliveryDays"
          @blur="clearErrors('numberOfDeliveryDays')"
          class="selectExample w-100"
          label="عدد أيام التسليم من كل أسبوع*"
          v-model="numberOfDeliveryDays"
        >
          <vs-select-item value="fiveDays" text="5 أيام كل أسبوع" />
          <vs-select-item value="sevenDays" text="7 أيام كل أسبوع" />
        </vs-select> -->
      </vs-col>
      <vs-col class="mt-3" vs-w="12" v-if="numberOfDeliveryDays == 'fiveDays'">
        <p>أيام التسليم من كل اسبوع</p>
        <div class="ob-dayRow">
          <div v-for="index in 7" :key="index">
            <vs-button
              :color="dayColor((date.getDay() + index - 1) % 7)"
              size="large"
              @click="toggleDayStatus((date.getDay() + index - 1) % 7)"
              class="m-1"
              :title="
                dayColor((date.getDay() + index - 1) % 7) == 'success'
                  ? 'تم الإختيار'
                  : 'اختر الآن'
              "
            >
              {{ deliveryDays[(date.getDay() + index - 1) % 7].text }}
            </vs-button>
            <br />
            <small>{{
              deliveryDays[(date.getDay() + index - 1) % 7].date.getDate() +
                "-" +
                (deliveryDays[(date.getDay() + index - 1) % 7].date.getMonth() +
                  1)
            }}</small>
          </div>
        </div>
      </vs-col>
    </vs-row>
    <vs-row vs-align="center" vs-justify="space-around" vs-w="12">
      <p class="text-danger" v-if="error.selectedPackage">
        {{ error.selectedPackage }}
      </p>
      <p class="text-danger" v-if="error.startDate">
        {{ error.startDate }}
      </p>

      <p class="text-danger" v-if="error.duration">
        {{ error.duration }}
      </p>

      <p class="text-danger" v-if="error.numberOfDeliveryDays">
        {{ error.numberOfDeliveryDays }}
      </p>
    </vs-row>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "@/assets/scss/datepicker.scss";

export default {
  components: {
    DatePicker,
  },
  computed: {
    deliveryDays() {
      return this.$store.state.packageInfo.deliveryDays;
    },
    date: {
      get() {
        return this.$store.state.packageInfo.startDate;
      },
      set(value) {
        this.$store.commit("SET_PACKAGE_INFO_INDEX", {
          index: "startDate",
          value,
        });
        this.$store.commit("SET_PACKAGE_DAYS_DATES", {
          value,
        });

        this.clearErrors("startDate");
      },
    },
    duration: {
      get() {
        return this.$store.state.packageInfo.duration;
      },
      set(value) {
        this.$store.commit("SET_PACKAGE_INFO_INDEX", {
          index: "duration",
          value,
        });
        this.clearErrors("duration");
      },
    },
    numberOfDeliveryDays: {
      get() {
        return this.$store.state.packageInfo.numberOfDeliveryDays;
      },
      set(value) {
        this.$store.commit("SET_PACKAGE_INFO_INDEX", {
          index: "numberOfDeliveryDays",
          value,
        });
        if (value == "sevenDays") {
          this.$store.commit("DELIVER_MEALS_OVER_THE_WHOLE_WEEK");
        } else {
          this.$store.commit("DELIVER_MEALS_OVER_THE_FIVE_DAYS_A_WEEK");
        }

        this.clearErrors("numberOfDeliveryDays");
      },
    },
    selectedPackage: {
      get() {
        return this.$store.state.packageInfo.selectedPackage;
      },
      set(value) {
        this.$store.commit("SET_PACKAGE_INFO_INDEX", {
          index: "selectedPackage",
          value,
        });
        this.clearErrors("selectedPackage");
      },
    },
    error() {
      return this.$store.state.error.firstTab;
    },
    buttonColor() {
      let error = this.error;
      return id => {
        if (error.selectedPackage != "") {
          return "danger";
        }
        return this.selectedPackage == id ? "success" : "primary";
      };
    },
    dateAfter48Hours() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const dateAfter48Hours = today.setDate(today.getDate() + 2);

      return dateAfter48Hours;
    },
  },
  data() {
    return {
      numberOfDeliveryDaysOptions: [
        {
          value: "fiveDays",
          text: "5 أيام كل أسبوع",
        },
        {
          value: "sevenDays",
          text: "7 أيام كل أسبوع",
        },
      ],
      durationOptions: [
        {
          value: "Month",
          text: "شهر",
        },
        {
          value: "Week",
          text: "اسبوع",
        },
      ],
      weekday: [
        "الأحد",
        "الإثنين",
        "الثلاثاء",
        "الأربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ],
    };
  },
  methods: {
    dayColor(index) {
      let day = this.deliveryDays[index];
      return day.selected ? "success" : "#ccc";
    },
    disabledBefore48HoursFromNow(date) {
      if (date < new Date(2020, 8, 17)) {
        return true;
      }
      return date < this.dateAfter48Hours;
    },
    setPackage(id) {
      this.selectedPackage = id;
    },
    clearErrors(index) {
      this.$store.commit("CLEARE_FIRST_TAB_ERROR", {
        index,
      });
    },
    toggleDayStatus(index) {
      this.$store.commit("TOGGLE_DAY_DELIVERY_STATUS", index);
      this.clearErrors("numberOfDeliveryDays");
    },
  },
};
</script>

<style scoped>
.ob-dayRow {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
</style>
