import Vue from "vue";
import App from "./App.vue";

import store from "./store";

import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css"; //Vuesax styles
Vue.use(Vuesax, {
  rtl: true,
  theme: {
    colors: {
      primary: "#6d5544",
    },
  },
});
import VueAwesomeSwiper from "vue-awesome-swiper";

Vue.use(VueAwesomeSwiper);
import "swiper/css/swiper.css";
import "material-icons/iconfont/material-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";

import axios from "axios";

import router from "./router";

import VueTelInput from "vue-tel-input";
Vue.use(VueTelInput);
const headers = {
  "Content-Type": "text/plain",
};

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
Vue.use({
  install(Vue) {
    Vue.prototype.$http = axios.create({
      baseURL: "https://api.dawsat.com/",
      headers,
    });
  },
});

import i18n from "./i18n/i18n";

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount("#app");
