// en,ar
export default {
  ar: {
    inRiad: "فقط في مدينة الرياض  ",
    deliveryMoto:
      "الان يمكنك الحصول على قائمة طعام دوسات الصحي الى منزلكم مباشرة!",
    steps: "الخطوات ",
    mainSectionMoto:
      "ما تتعب بتحضير الوجبات وانت بالبيت.... انزل بالوزن مع دوسات واستمتع بأشهى الاكلات",
    step1: "الخطوة١ ",
    step1Note:
      "اشترك لمدة أسبوع او شهر، الرجاء تعبئة بياناتك الصحيحة العنوان ورقم الهاتف والايميل لوصول الطلب في الوقت.",
    step2: "الخطوة٢ ",
    step2Note: "سيتم ارسال رسالة ترحيب وتأكيد على الاشتراك",
    step3: "الخطوة٣ ",
    step3Note:
      "لمجرد تسجيلك اليوم طلبك يبدأ بالوصول بعد يومين الطعام سيصلك بحقيبة مجهزة لحفظ الحرارة",
    whatToGet: "على ماذا تحصل ",
    dailyMeals: "وجبات يومية",
    dailyMealsNote:
      "خليك بالبيت وجباتك اللذيذة بتوصل لباب البيت اختر من بين قائمة الطعام الخاصة بدوسات وبإمكانك تغيير المنيو قبل 24 ساعة   وجبة افطار يومية لذيذة مع تفاصيل أوقات تناول الطعام لتفعيل حرق الدهون والنزول بالوزن اختار ما يناسب ذوقك من اطباق خليجية وشامية وعربية والمائدة العالمية",
    dawsatApp: "تطبيق دوسات",
    dawsatAppNote:
      "  تمتعو بأشهى الأكلات الصحية من قائمة دوسات وتطبيق دوسات راح يساعدك كثير في نصائح وتواصل فعال مع مجتمع دوسات الرائع",
    choosePackage: "إخيار الحزمة  ",
    chooseNow: "أختر الان",
    orderInfo: "معلومات الطلب",
    personalInfoSectionNote:
      "أدخل معلومات الأفراد للحصول على أفضل وجبة صحية حسب نظام دوسات",
  },
  ar_sa: {
    inRiad: "فقط في مدينة الرياض  ",
    deliveryMoto:
      "الحين يمكنك الحصول على قائمة طعام دوسات الصحية الى منزلكم مباشرة!",

    steps: "الخطوات ",
    mainSectionMoto: "",
    step1: "الخطوة١ ",
    step1Note:
      "رح تشترك معانا لمدة أسبوع او شهر وتأكد أنك تحط بياناتك بشكل صحيح العنوان ورقم الهاتف والايميل عشان يوصلك الطلب بالوقت المحدد",
    step2: "الخطوة٢ ",
    step2Note: "راح توصلك رسالة ترحيب وتأكيد على اشتراكك.",
    step3: "الخطوة٣ ",
    step3Note:
      "بمجرد ما إنك تسجل اليوم رح يوصلك طلبك بعد يومين بشنطة مجهزة لحفظ الحرارة.",
    whatToGet: "مزايا الاشتراك ",
    dailyMeals: "وجبات يومية",
    dailyMealsNote:
      "اختار من قائمة الطعام الخاصة بدوسات من وجبة الإفطار يوميا الى وجبات العشاء الشهية من الاطباق الخليجية  الي تحبها والعربية والمائدة العالمية",
    dawsatApp: "تطبيق دوسات",
    dawsatAppNote:
      "  استمتعوا بألذ طعام صحي من منيو دوسات وتطبيق دوسات رح يساعدك كثير ويعطيك نصائح وتواصل مستمر مع مجتمع دوسات الرائع.",
    choosePackage: "اختار الحزمة  ",
    chooseNow: "اختار الحين",
    orderInfo: "معلومات الطلب",
    personalInfoSectionNote:
      "دخل معلوماتك كفرد عشان تحصل على انسب وجبة صحية حسب نظام دوسات",
  },
};
