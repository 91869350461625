import { getField } from "vuex-map-fields";

const getters = {
  getField,
  buttonColor: state => (mealId, selectedMeal, dayIndex, personIndex) => {
    let found =
      state.people[personIndex].days[dayIndex][selectedMeal].id == mealId;
    if (found) {
      return "success";
    } else {
      return "danger";
    }
  },
  avatarColor: state => index => {
    let found = state.selectedMeals.find(element => {
      if (element.personIndex == index) {
        return true;
      }
      return false;
    });
    if (found) {
      return "success";
    } else {
      return "#cccccc";
    }
  },
  selectedDeliveryDays(state) {
    let selectedDays = state.packageInfo.deliveryDays.filter(
      item => item.selected
    );

    let sortedDays = selectedDays.sort(function(a, b) {
      return a.date - b.date;
    });
    return sortedDays;
  },
  ////////////////
  //validation
  validateFirstTap(state, getters) {
    let data = state.packageInfo;
    let errors = [];
    let pass = true;
    if (!data.selectedPackage) {
      pass = false;
      errors.push({
        index: "selectedPackage",
        message: "رجاءً اختر حزمة للمتابعة",
      });
    }
    if (!data.startDate) {
      pass = false;
      errors.push({
        index: "startDate",
        message: "رجاءً قم بتحدد تاريخ البداية",
      });
    }
    if (!data.duration) {
      pass = false;
      errors.push({
        index: "duration",
        message: "رجاءً قم بتحدد مدة الإشتراك",
      });
    }
    if (data.numberOfDeliveryDays == "") {
      pass = false;
      errors.push({
        index: "numberOfDeliveryDays",
        message: "رجاءً قم باختيار عدد أيام التسليم من كل أسبوع ",
      });
    }
    if (
      data.numberOfDeliveryDays == "fiveDays" &&
      getters.selectedDeliveryDays.length != 5
    ) {
      pass = false;
      errors.push({
        index: "numberOfDeliveryDays",
        message: "رجاءً قم باختيار 5 أيام لإستلام الوجبات خلالها ",
      });
    }

    return { pass, errors };
  },
  validateSecondTap(state, getters) {
    let data = state.people;
    let errors = { people: [] };
    let pass = true;
    let peopleError = false;
    let daysError = false;

    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const temp = {
        age: "",
        reasonToJoin: "",
        weight: "",
        height: "",
        gender: "",
        mealMethod: "",
        days: [],
      };

      if (element.age == "") {
        pass = false;
        peopleError = true;
        temp.age = "رجاءً قم بإدخال عمرك";
      }
      if (element.reasonToJoin == "") {
        pass = false;
        peopleError = true;
        temp.reasonToJoin = "رجاءً قم بإدخال سبب إشتراكك";
      }
      if (element.weight == "") {
        pass = false;
        peopleError = true;
        temp.weight = "رجاءً قم بإدخال وزنك";
      }
      if (element.height == "") {
        pass = false;
        peopleError = true;
        temp.height = "رجاءً قم بإدخال طولك";
      }
      if (element.gender == "") {
        pass = false;
        peopleError = true;
        temp.gender = "رجاءً قم بإدخال خانة الجنس";
      }
      if (element.mealMethod == "") {
        pass = false;
        peopleError = true;
        temp.mealMethod = "رجاءً قم بإختيار وجبتك";
      }

      let daysLength = null;
      if (state.packageInfo.duration == "Month") {
        daysLength = getters.selectedDeliveryDays.length * 4;
      } else if (state.packageInfo.duration == "Week") {
        daysLength = getters.selectedDeliveryDays.length;
      }
      for (let index = 0; index < daysLength; index++) {
        const day = element.days[index];
        if (day.lunch.id || day.lunchMain.id || day.drop.id) continue;
        else {
          temp.days.push(index);
          daysError = true;
          pass = false;
        }
      }
      errors.people.push(temp);
    }
    if (peopleError)
      errors.peopleMessage = "رجاءً قم بإكمال إداخال معلومات الأشخاص";

    if (daysError)
      errors.dayMessage =
        "رجاءً قم بإدخال وجبة الغداء على الأقل لكل يوم من أيام الإشتراك";

    return { pass, errors };
  },
  validateThirdTap(state) {
    let data = state.deliveryInfo;
    let errors = [];
    let pass = true;
    if (data.name == "") {
      pass = false;
      errors.push({
        index: "name",
        message: "رجاءً ادخل اسمك الكامل",
      });
    }
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(String(data.email).toLowerCase())) {
      pass = false;
      errors.push({
        index: "email",
        message: "رجاءً قم بإدخال ايميل صحيح",
      });
    }
    if (data.phone == "") {
      pass = false;
      errors.push({
        index: "phone",
        message: "رجاءً قم بإدخال رقم هاتف صحيح",
      });
    }
    if (data.deliveryAddress == "") {
      pass = false;
      errors.push({
        index: "deliveryAddress",
        message: "رجاءً قم بإدخال عنوانك الكامل",
      });
    }
    if (data.area == "") {
      pass = false;
      errors.push({
        index: "area",
        message: "رجاءً قم بإدخال المنطقة ",
      });
    }

    return { pass, errors };
  },
};

export default getters;
