import { updateField } from "vuex-map-fields";

const mutations = {
  updateField,
  SET_SELECTED_PERSON(state, value) {
    state.selectedPersonIndex = value;
  },
  SET_NUMBER_OF_PEOPLE(state, payload) {
    state.numberOfPeople = payload;
  },
  ADD_SELECTED_PACKAGE(state, payload) {
    let mealIndex = state.selectedMeals.findIndex(element => {
      if (element.personIndex == state.selectedPersonIndex) {
        return true;
      }
      return false;
    });

    if (mealIndex != -1) {
      state.selectedMeals[mealIndex].mealId = payload.mealId;
    } else {
      const data = {
        mealId: payload.mealId,
        personIndex: state.selectedPersonIndex,
      };
      state.selectedMeals.push(data);
    }
  },
  /////////////////////////////
  // First Tab functions
  DELIVER_MEALS_OVER_THE_WHOLE_WEEK(state) {
    state.packageInfo.deliveryDays.forEach(day => {
      day.selected = true;
    });
  },
  DELIVER_MEALS_OVER_THE_FIVE_DAYS_A_WEEK(state) {
    let count = 0;
    state.packageInfo.deliveryDays.forEach(day => {
      count++;
      if (count <= 5) {
        day.selected = true;
      } else {
        day.selected = false;
      }
    });
  },
  SET_PACKAGE_INFO_INDEX(state, payload) {
    state.packageInfo[payload.index] = payload.value;
  },
  CLEAR_PEOPLE_ERRORS(state, payload) {
    state.error.secondTab[payload.index][payload.selected][payload.peopleKey] =
      "";
  },
  CLEAR_INDEX_ERRORS(state, index) {
    state.error[index] = "";
  },
  CLEAR_ALL_ERRORS(state) {
    for (let key in state.error) {
      this.error[key] = "";
    }
  },
  TOGGLE_DAY_DELIVERY_STATUS(state, index) {
    state.packageInfo.deliveryDays[index].selected = !state.packageInfo
      .deliveryDays[index].selected;
  },
  SET_PACKAGE_DAYS_DATES(state, payload) {
    let date = payload.value;
    for (let index = 0; index < 7; index++) {
      let newIndex = (date.getDay() + index) % 7;
      state.packageInfo.deliveryDays[newIndex].date = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + index
      );
    }
  },

  //////////////
  //second tab
  COPY_SELECTED_WEEK(state, payload) {
    const selectedWeek = payload.selectedWeek;
    const selectedPersonIndex = payload.personIndex;
    const weekLength = payload.weekLength;
    const days = state.people[selectedPersonIndex].days;
    const offset = selectedWeek * weekLength;
    for (let index = 0; index < weekLength; index++) {
      const baseDayIndex = index + offset;
      days[(baseDayIndex + weekLength * 1) % (weekLength * 4)] = Object.assign(
        {},
        days[baseDayIndex]
      );

      days[(baseDayIndex + weekLength * 2) % (weekLength * 4)] = Object.assign(
        {},
        days[baseDayIndex]
      );
      days[(baseDayIndex + weekLength * 3) % (weekLength * 4)] = Object.assign(
        {},
        days[baseDayIndex]
      );
    }

    state.people[selectedPersonIndex].days = days;
  },
  ADD_PERSON(state, payload) {
    state.people.push(payload);
    const personError = {
      age: "",
      reasonToJoin: "",
      weight: "",
      height: "",
      allergies: "",
      gender: "",
      mealMethod: "",
      days: [],
    };
    state.error.secondTab.people.push(personError);
  },
  REMOVE_PERSON(state) {
    state.people.pop();
    state.error.people.pop();
    state.selectedPersonIndex = 0;
  },
  SET_PERSON_DATA(state, payload) {
    state.people[payload.arrayIndex][payload.index] = payload.value;
  },
  SET_PERSON_DAY_INFO(state, payload) {
    if (payload.selectedMeal == "drop" || payload.selectedMeal == "date") {
      state.people[payload.personIndex].days[payload.dayIndex][
        payload.selectedMeal
      ] = payload.mealId;
      return;
    }

    state.people[payload.personIndex].days[payload.dayIndex][
      payload.selectedMeal
    ]["id"] = payload.mealId;
    state.people[payload.personIndex].days[payload.dayIndex][
      payload.selectedMeal
    ]["price"] = payload.price;
    state.people[payload.personIndex].days[payload.dayIndex][
      payload.selectedMeal
    ]["name"] = payload.englishName;
  },
  CLEARE_CUSTMIZED_MEALS(state, payload) {
    state.people[payload.personIndex].days[payload.dayIndex]["lunchMain"] = {
      id: "",
      price: "",
      name: "",
    };
    state.people[payload.personIndex].days[payload.dayIndex][
      "lunchCarbohydrate"
    ] = { id: "", price: "" };
    state.people[payload.personIndex].days[payload.dayIndex]["lunchSalad"] = {
      id: "",
      price: "",
      name: "",
    };
    state.people[payload.personIndex].days[payload.dayIndex]["lunchDessert"] = {
      id: "",
      price: "",
      name: "",
    };
    state.people[payload.personIndex].days[payload.dayIndex]["lunchSoup"] = {
      id: "",
      price: "",
      name: "",
    };
    state.people[payload.personIndex].days[payload.dayIndex][
      "lunchSideDish"
    ] = {
      id: "",
      price: "",
      name: "",
    };
  },
  CLEARE_FULL_MEAL(state, payload) {
    state.people[payload.personIndex].days[payload.dayIndex]["lunch"] = {
      id: "",
      price: "",
      name: "",
    };
  },

  /////////////////////////
  /// third tab
  SET_DELIVERY_INFO_INDEX(state, payload) {
    state.deliveryInfo[payload.index] = payload.value;
  },
  ///////////////
  //validation
  /**
   * @param {vuex state} state
   * @param {index} payload
   */
  CLEARE_FIRST_TAB_ERROR(state, payload) {
    state.error.firstTab[payload.index] = "";
  },
  CLEARE_SECOND_TAB_ERROR(state, payload) {
    state.error.secondTab[payload.index] = "";
  },

  CLEAR_DAYS_ERRORS(state, payload) {
    const index = state.error.secondTab.people[
      payload.personIndex
    ].days.indexOf(payload.dayIndex);
    if (index > -1) {
      state.error.secondTab.people[payload.personIndex].days.splice(index, 1);
    }
    if (state.error.secondTab.people[payload.personIndex].days.length == 0)
      state.error.secondTab.dayMessage = "";
  },
  CLEARE_THIRD_TAB_ERROR(state, payload) {
    state.error.thirdTab[payload.index] = "";
  },

  /**
   *
   * @param {vuex state} state
   * @param {errors[{index,message}]} payload
   */
  SET_FIRST_TAB_ERRORS(state, payload) {
    if (payload)
      for (let key in payload.errors) {
        state.error.firstTab[payload.errors[key].index] =
          payload.errors[key].message;
      }
  },
  /**
   *
   * @param {vuex state} state
   * @param {errors:[]} payload
   */
  SET_SECOND_TAB_ERRORS(state, payload) {
    if (payload) {
      if (payload.errors.peopleMessage)
        state.error.secondTab.peopleMessage = payload.errors.peopleMessage;
      if (payload.errors.dayMessage)
        state.error.secondTab.dayMessage = payload.errors.dayMessage;
      for (let key in payload.errors.people) {
        for (let key2 in payload.errors.people[key]) {
          state.error.secondTab.people[key][key2] =
            payload.errors.people[key][key2];
        }
      }
    }
  },
  SET_THIRD_TAB_ERRORS(state, payload) {
    if (payload)
      for (let key in payload.errors) {
        state.error.thirdTab[payload.errors[key].index] =
          payload.errors[key].message;
      }
  },
};

export default mutations;
