// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////
import person from "./constants/person.json";
const state = {
  packageInfo: {
    numberOfDeliveryDays: "",
    selectedPackage: "",
    startDate: "",
    duration: "",
    deliveryDays: [
      {
        date: "",
        text: "الأحد",
        selected: true,
      },
      {
        date: "",
        text: "الإثنين",
        selected: true,
      },
      {
        date: "",
        text: "الثلاثاء",
        selected: true,
      },
      {
        date: "",
        text: "الأربعاء",
        selected: true,
      },
      {
        date: "",
        text: "الخميس",
        selected: true,
      },
      {
        date: "",
        text: "الجمعة",
        selected: false,
      },
      {
        date: "",
        text: "السبت",
        selected: false,
      },
    ],
  },
  deliveryInfo: {
    name: "",
    email: "",
    phone: "",
    deliveryAddress: "",
    country: "Saudi Arabia",
  },
  people: [Object.assign({}, person)],
  error: {
    firstTab: {
      selectedPackage: "",
      startDate: "",
      duration: "",
      numberOfDeliveryDays: "",
    },
    secondTab: {
      peopleMessage: "",
      dayMessage: "",
      people: [
        {
          age: "",
          reasonToJoin: "",
          weight: "",
          height: "",
          allergies: "",
          gender: "",
          mealMethod: "",
          days: [],
        },
      ],
    },
    thirdTab: {
      name: "",
      email: "",
      phone: "",
      deliveryAddress: "",
      area: "",
    },
  },

  numberOfPeople: 2,
  selectedPersonIndex: 1,
  selectedMeals: [],
  token: "",
};

export default state;
