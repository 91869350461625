<template>
  <div class="container">
    <div class="logo-container">
      <a href="https://dawsat.com/">
        <img
          class="img-fluid logo"
          src="https://s3-me-south-1.amazonaws.com/wp-dawsat/wp-content/uploads/2019/12/26123046/enlogotran.png"
          alt="دوسات"
        />
      </a>
    </div>

    <div class="row" style="align-items: center;">
      <div class="col">
        <div class="py-5 text-center">
          <img
            src="/images/banner.jpg"
            alt
            class="img-responsive"
            width="300"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <h4>
          دوسات البرنامج الاول لحساب نقاط المائدة العربية ....والنزول بالوزن
          بدون حرمان
        </h4>
        <h2>{{ $t("inRiad") }}</h2>
        <p>{{ $t("deliveryMoto") }}</p>
      </div>
    </div>

    <!-- Services section -->
    <section id="what-we-do" class="mb-3">
      <div class="container-fluid">
        <div class="row mb-5">
          <div class="col-12">
            <div class="embed-wrapper">
              <iframe
                width=""
                height=""
                src="https://www.youtube.com/embed/hcUlsJ_0KKE?rel=0&modestbranding=0&loop=0&controls=0&autoplay=0&fs=0&showinfo=0&color=Red&vq=720p"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>

        <h2 class="section-title mb-2 h1">{{ $t("steps") }}</h2>
        <p class="text-center text-muted h5">
          {{ $t("mainSectionMoto") }}
        </p>
        <div class="row mt-5">
          <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="card">
              <div class="card-block block-1">
                <h3 class="card-title">{{ $t("step1") }}</h3>
                <p class="card-text">
                  {{ $t("step1Note") }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="card">
              <div class="card-block block-1">
                <h3 class="card-title">{{ $t("step2") }}</h3>
                <p class="card-text">
                  {{ $t("step2Note") }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="card">
              <div class="card-block block-1">
                <h3 class="card-title">{{ $t("step3") }}</h3>
                <p class="card-text">
                  {{ $t("step3Note") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <h2 class="section-title mb-2 h1">
          {{ $t("whatToGet") }}
        </h2>
        <div class="row mt-5">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div class="card">
              <div class="card-block block-1">
                <h3 class="card-title">
                  {{ $t("dailyMeals") }}
                </h3>
                <p class="card-text">
                  {{ $t("dailyMealsNote") }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div class="card">
              <div class="card-block block-1">
                <h3 class="card-title">
                  {{ $t("dawsatApp") }}
                </h3>
                <p class="card-text">
                  {{ $t("dawsatAppNote") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form-wizard></form-wizard>
    </section>
    <vs-row vs-type="flex" vs-justify="space-around" class="footer">
      <a href="http://dawsat.com">الموقع الرسمي</a>
      <a href="http://dawsat.app.link/">تطبيق دوسات</a>
      <a href="https://dawsat.com/terms-and-conditions-kitopi"
        >الشروط و الأحكام</a
      >
    </vs-row>
  </div>
</template>

<script>
import FormWizard from "@/components/FormWizard";
export default {
  components: {
    FormWizard,
  },
};
</script>

<style>
@font-face {
  font-family: "TheSansArabic-Bold";
  src: url(/fonts/TheSansArabic-Bold.eot);
  src: url(/fonts/TheSansArabic-Bold.eot?#iefix) format("embedded-opentype"),
    url(/fonts/TheSansArabic-Bold.woff2) format("woff2"),
    url(/fonts/TheSansArabic-Bold.woff) format("woff"),
    url(/fonts/TheSansArabic-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "TheSansArabic-SemiBold";
  src: url(/fonts/TheSansArabic-SemiBold.eot);
  src: url(/fonts/TheSansArabic-SemiBold.eot?#iefix) format("embedded-opentype"),
    url(/fonts/TheSansArabic-SemiBold.woff2) format("woff2"),
    url(/fonts/TheSansArabic-SemiBold.woff) format("woff"),
    url(/fonts/TheSansArabic-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "TheSansArabic-ExtraBold";
  src: url(/fonts/TheSansArabic-ExtraBold.eot);
  src: url(/fonts/TheSansArabic-ExtraBold.eot?#iefix)
      format("embedded-opentype"),
    url(/fonts/TheSansArabic-ExtraBold.woff2) format("woff2"),
    url(/fonts/TheSansArabic-ExtraBold.woff) format("woff"),
    url(/fonts/TheSansArabic-ExtraBold.ttf) format("truetype");
}
body {
  background-color: #f4eee2;
  direction: rtl;
  text-align: right;
  font-family: "TheSansArabic-SemiBold" !important;
}
.list-group {
  padding-right: 0;
}

section {
  padding: 60px 0;
}
section .section-title {
  text-align: center;
  color: #6d5544;
  margin-bottom: 50px;
  text-transform: uppercase;
}
#what-we-do {
  background: #ffffff;
}
#what-we-do .card {
  padding: 1rem !important;
  border: none;
  margin-bottom: 1rem;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
#what-we-do .card:hover {
  -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}
#what-we-do .card .card-block {
  padding-left: 50px;
  position: relative;
}
#what-we-do .card .card-block a {
  color: #007b5e !important;
  font-weight: 700;
  text-decoration: none;
}
#what-we-do .card .card-block a i {
  display: none;
}
#what-we-do .card:hover .card-block a i {
  display: inline-block;
  font-weight: 700;
}
#what-we-do .card .card-block:before {
  font-family: FontAwesome;
  position: absolute;
  font-size: 39px;
  color: #6d5544;
  left: 0;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}
#what-we-do .card .block-1:before {
  content: "\275B";
}
#what-we-do .card .block-2:before {
  content: "\275B";
}
#what-we-do .card .block-3:before {
  content: "\275B";
}

#what-we-do .card:hover .card-block:before {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.logo-container {
  background: #6a5544;
  border-radius: 0.3rem;
  position: absolute;
  height: auto;
  top: 10px;
  z-index: 9999;
}
.logo {
  margin: 20px;
  width: 70px;
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .logo {
    margin: 10px;
    width: 40px;
  }
}

.footer a {
  color: #2c3e50;
}
.embed-wrapper {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}
.embed-wrapper iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
