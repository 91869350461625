<template>
  <form-wizard
    color="rgba(var(--vs-primary), 1)"
    :title="null"
    :subtitle="null"
    finishButtonText="أدفع الآن"
    backButtonText="الرجوع"
    nextButtonText="التالي"
    @on-complete="formSubmitted"
    @on-validate="handleValidation"
    @on-error="handleErrorMessage"
    id="form-header"
  >
    <!-- tab 1 content -->
    <tab-content
      title="إختيار الحزمة"
      class="mb-5"
      icon="fas fa-box-open"
      :before-change="validateFirstTap"
    >
      <First-Tab></First-Tab>
    </tab-content>

    <!-- tab 2 packge info -->

    <tab-content
      :before-change="validateSecondTap"
      title="تخصيص الحزمة"
      class="mb-5"
      icon="fas fa-wrench"
    >
      <second-tab></second-tab>
    </tab-content>
    <!-- tab 3 content -->

    <tab-content
      :before-change="validateThirdTap"
      title="معلومات التواصل"
      class="mb-5"
      icon="fas fa-info-circle"
    >
      <!-- order info -->
      <third-tab></third-tab>
    </tab-content>

    <!-- tab 4 content -->
    <tab-content
      title="الدفع"
      class="mb-5"
      icon="fas fa-credit-card"
      :before-change="validateForthTap"
    >
      <vs-row vs-justify="space-around">
        <vs-col
          class="mt-3 text-right"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="4"
          vs-xs="12"
        >
          <vs-list>
            <vs-list-header title="ملخص الطلب"></vs-list-header>
            <vs-list-item
              v-for="(item, index) in people"
              :key="index"
              :title="summaryTitle(index)"
              :subtitle="summarySubtitle(item)"
            >
              <span style="float:left"
                >{{ mealPrice(index) }} <br />
                <small style="float:left">SAR</small></span
              >
            </vs-list-item>

            <vs-list-item title="التوصيل">
              <span style="float:left"
                >{{ deliveryPrice() }} <br />
                <small style="float:left;text-align:left">SAR</small></span
              >
            </vs-list-item>

            <vs-list-header
              :title="`المجموع: ${fullPrice()} SAR`"
              color="primary"
            ></vs-list-header>
          </vs-list>
        </vs-col>
        <vs-col class="mt-3" vs-w="6" vs-xs="12">
          <vs-tabs alignment="fixed">
            <vs-tab label="Visa/Mastercard">
              <credit-card @cardTokenized="sendData"></credit-card>
            </vs-tab>
            <!-- <vs-tab label="Apple Pay">
              <div></div>
            </vs-tab> -->
          </vs-tabs>
          <vs-row vs-type="flex" vs-justify="center" vs-align="center">
            <vs-switch color="success" v-model="termsAndConditions">
            </vs-switch>

            <small :class="errors.termsAndConditions ? 'text-danger' : ''">
              انا اوافق على<a
                href="https://dawsat.com/terms-and-conditions-kitopi"
              >
                شروط الاستخدام</a
              >
              و
              <a href="https://dawsat.com/privacy-policy/">سياسة الخصوصية</a> في
              دوسات
            </small>
          </vs-row>
        </vs-col>
      </vs-row>
    </tab-content>
  </form-wizard>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import FirstTab from "./tabs/FirstTab.vue";
import SecondTab from "./tabs/SecondTab.vue";
import ThirdTab from "./tabs/ThirdTab.vue";

import CreditCard from "./CreditCard";

export default {
  components: {
    FormWizard,
    TabContent,
    CreditCard,
    FirstTab,
    SecondTab,
    ThirdTab,
  },
  computed: {
    termsAndConditions: {
      get() {
        return this.termsAndConditionsRef;
      },
      set(value) {
        if (value) this.errors.termsAndConditions = false;
        this.termsAndConditionsRef = value;
      },
    },
    thirdTabErrors() {
      return this.$store.state.error.thirdTab;
    },
    people() {
      return this.$store.state.people;
    },
  },
  data() {
    return {
      termsAndConditionsRef: false,

      meals: [],
      numperOfSubscribers: 0,
      selected: 0,
      selectedWeek: 1,
      noMeal: false,
      errors: {
        termsAndConditions: false,
      },
      data: {
        token: "",
        duration: "",
        name: "",
        email: "",
        phone: "",
        deliveryAddress: "",
        selectedPackage: "",
        area: "",
        country: "Saudi Arabia",
        people: [
          {
            age: 25,
            jobType: "",
            weight: 60,
            height: 170,
            allergies: "",
            gender: "",
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    formSubmitted() {
      this.$vs.loading();
      window.Frames.submitCard();
    },

    sendData(token) {
      const headers = {
        "Content-Type": "text/plain",
      };
      let data = {};
      data.people = Array.from(this.people);
      data.packageInfo = Object.assign({}, this.$store.state.packageInfo);
      data.packageInfo.startDate = `${data.packageInfo.startDate.getFullYear()}-${data.packageInfo.startDate.getMonth() +
        1}-${data.packageInfo.startDate.getDate()}`;
      data.deliveryInfo = Object.assign({}, this.$store.state.deliveryInfo);
      data.token = token;
      console.log(JSON.stringify(data));
      this.$http
        .post("/api/v2/checkout/payment", data, {
          headers,
        })
        .then(() => {
          this.$vs.loading.close();
          alert("تم اشتراكاك بنجاح");
        })
        .catch(() => {
          this.$vs.loading.close();
          alert("حدث خطأ ما الرجاء المحاولة فيما بعد");
        });
    },

    clearThirdTabErrors(index) {
      this.$store.commit("CLEARE_THIRD_TAB_ERROR", {
        index,
      });
    },
    mealPriceForFullMeals() {
      let duration = this.$store.state.packageInfo.duration;
      let numberOfDeliveryDays = this.$store.state.packageInfo
        .numberOfDeliveryDays;
      if (duration == "Week") {
        if (numberOfDeliveryDays == "fiveDays") return 200;
        else if (numberOfDeliveryDays == "sevenDays") return 300;
      } else if (duration == "Month") {
        if (numberOfDeliveryDays == "fiveDays") return 900;
        else if (numberOfDeliveryDays == "sevenDays") return 999;
      }
    },
    roundPrice(price) {
      return Math.round(price * 2) / 2 - 0.01;
    },
    deliveryPrice() {
      for (let index = 0; index < this.people.length; index++) {
        const person = this.people[index];
        if (person.mealMethod == "full-meal") {
          if (this.$store.state.packageInfo.duration == "Month") {
            return 0;
          } else {
            return 50;
          }
        } else {
          return 50;
        }
      }
    },
    mealPrice(personIndex) {
      if (this.people[personIndex].mealMethod == "full-meal") {
        return this.mealPriceForFullMeals();
      } else {
        let daysLength = null;
        let price = 0;
        let state = this.$store.state;
        let getters = this.$store.getters;
        if (state.packageInfo.duration == "Month") {
          daysLength = getters.selectedDeliveryDays.length * 4;
        } else if (state.packageInfo.duration == "Week") {
          daysLength = getters.selectedDeliveryDays.length;
        }
        for (let index = 0; index < daysLength; index++) {
          const day = this.people[personIndex].days[index];
          price += this.roundPrice(day.lunchMain.price);
          price += this.roundPrice(day.lunchSideDish.price);
          price += this.roundPrice(day.lunchCarbohydrate.price);
          price += this.roundPrice(day.lunchSalad.price);
          price += this.roundPrice(day.lunchDessert.price);
          price += this.roundPrice(day.lunchSoup.price);
        }
        return Number(price).toFixed(2);
      }
    },
    fullPrice() {
      let price = 0;
      for (let index = 0; index < this.people.length; index++) {
        price += Number(this.mealPrice(index));
      }
      console.log("before", price);
      price += Number(this.deliveryPrice());
      console.log("after", price);
      return Number(price).toFixed(2);
    },
    summaryTitle(index) {
      index = index + 1;
      let wordMap = {
        1: "الأول",
        2: "الثاني",
        3: "الثالث",
        4: "الرابع",
        5: "الخامس",
        6: "السادس",
        7: "السابع",
        8: "الثامن",
        9: "التاسع",
      };
      let text = "";
      if (index < 10) {
        text = "الشخص " + wordMap[index];
      } else {
        text = "الشخص " + index;
      }
      const mealMethodMap = {
        "customized-meal": "حزمة مخصصة",
        "full-meal": "حزم دوسات المميزة",
      };

      let finalText = `${text}|${
        mealMethodMap[this.people[index - 1].mealMethod]
      }`;
      return finalText;
    },
    summarySubtitle(item) {
      let genderMap = {
        male: "ذكر",
        female: "أنثى",
      };
      let heightText = "الطول:" + (item.height ? item.height : "لم يحدد");
      let weightText = "الوزن:" + (item.weight ? item.weight : "لم يحدد");
      let ageText = "العمر:" + (item.age ? item.age : "لم يحدد");
      let allergiesText =
        "الحساسية:" + (item.allergies ? item.allergies : "لم يحدد");

      let genderText =
        "الجنس:" + (item.gender ? genderMap[item.gender] : "لم يحدد");
      let result =
        heightText +
        "|" +
        weightText +
        "|" +
        ageText +
        "|" +
        allergiesText +
        "|" +
        genderText;
      return result;
    },
    submitCard() {},

    ///////////
    // validation
    handleValidation: function(isValid) {
      // handleValidation: function(isValid, tabIndex) {
      if (isValid) {
        const element = "#form-header";
        const duration = 500;
        const options = {
          easing: "linear",
        };
        this.$scrollTo(element, duration, options);
      }
      // console.log("Tab: " + tabIndex + " valid: " + isValid);
    },
    handleErrorMessage: function(data) {
      if (data)
        if (data.tab == 1) {
          this.$store.commit("SET_FIRST_TAB_ERRORS", data);
        } else if (data.tab == 2) {
          this.$store.commit("SET_SECOND_TAB_ERRORS", data);
        } else if (data.tab == 3) {
          this.$store.commit("SET_THIRD_TAB_ERRORS", data);
        } else if (data.tab == 4) {
          this.errors.termsAndConditions = true;
        }
      // this.$store.commit("setErrors", data);
    },
    validateThirdTap() {
      let validationResult = this.$store.getters.validateThirdTap;
      validationResult.tab = 3;
      return new Promise((resolve, reject) => {
        if (validationResult.pass) {
          resolve(true);
        } else {
          reject(validationResult);
        }
      });
    },
    validateForthTap() {
      let pass = true;
      if (this.termsAndConditions == false) {
        pass = false;
      }
      let validationResult = { pass, tab: 4 };
      return new Promise((resolve, reject) => {
        if (validationResult.pass) {
          resolve(true);
        } else {
          reject(validationResult);
        }
      });
    },
    validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validateSecondTap() {
      let validationResult = this.$store.getters.validateSecondTap;
      validationResult.tab = 2;

      return new Promise((resolve, reject) => {
        if (validationResult.pass) {
          resolve(true);
        } else {
          reject(validationResult);
        }
      });
    },
    validateFirstTap() {
      let validationResult = this.$store.getters.validateFirstTap;
      validationResult.tab = 1;
      return new Promise((resolve, reject) => {
        if (validationResult.pass) {
          resolve(true);
        } else {
          reject(validationResult);
        }
      });
    },
  },
};
</script>

<style lang="scss">
/* vue-tel */
.vti__dropdown-list {
  right: -1px;
  text-align: right !important;
}
/* vue-form-wizard */
.vue-form-wizard .wizard-progress-bar {
  float: right;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-right {
  float: left;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left {
  float: right;
}
.ob-img {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.3rem;
}
/* select */

.selectExample {
  margin: 10px;
}
.con-select-example {
  display: flex;
  align-items: center;
  justify-content: center;
}
.con-select .vs-select {
  width: 100%;
}
@media (max-width: 550px) {
  .con-select {
    flex-direction: column;
  }
  .con-select .vs-select {
    width: 100%;
  }
}

/* button */
.wizard-btn:empty {
  display: none !important;
}

.pr-30px {
  padding-right: 30px !important;
}

/* select */

.ob-select.has-error {
  .vs__dropdown-toggle {
    border: 1px solid rgba(var(--vs-danger), 1) !important;
  }
}
</style>
