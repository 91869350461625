const actions = {
  sample({ commit }, val) {
    commit("SAMPLE", val);
  },
  clearErrors({ commit }, payload) {
    /**
     * params:
     * index
     * selected
     * peopleKey
     * }
     */

    if (payload.index) {
      if (payload.peopleKey) {
        commit("CLEAR_PEOPLE_ERRORS", payload);
        return;
      }
      commit("CLEAR_INDEX_ERRORS", payload.index);
      return;
    }
    commit("CLEAR_ALL_ERRORS");
  },
};

export default actions;
