<template>
  <div id="payment-form">
    <label for="card-number">رقم البطاقة</label>
    <div class="input-container card-number">
      <div class="icon-container">
        <img id="icon-card-number" src="images/card-icons/card.svg" alt="PAN" />
      </div>
      <div class="card-number-frame"></div>
      <div class="icon-container payment-method">
        <img id="logo-payment-method" />
      </div>
      <div class="icon-container">
        <img id="icon-card-number-error" src="images/card-icons/error.svg" />
      </div>
    </div>

    <div class="date-and-code">
      <div>
        <label for="expiry-date">تاريخ الإنتهاء</label>
        <div class="input-container expiry-date">
          <div class="icon-container">
            <img
              id="icon-expiry-date"
              src="images/card-icons/exp-date.svg"
              alt="Expiry date"
            />
          </div>
          <div class="expiry-date-frame"></div>
          <div class="icon-container">
            <img
              id="icon-expiry-date-error"
              src="images/card-icons/error.svg"
            />
          </div>
        </div>
      </div>

      <div>
        <label for="cvv">الرقم السري</label>
        <div class="input-container cvv">
          <div class="icon-container">
            <img id="icon-cvv" src="images/card-icons/cvv.svg" alt="CVV" />
          </div>
          <div class="cvv-frame"></div>
          <div class="icon-container">
            <img id="icon-cvv-error" src="images/card-icons/error.svg" />
          </div>
        </div>
      </div>
    </div>

    <div>
      <span class="error-message error-message__card-number"></span>
      <span class="error-message error-message__expiry-date"></span>
      <span class="error-message error-message__cvv"></span>
    </div>

    <p class="success-payment-message"></p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      card: {},
    };
  },
  mounted() {
    this.card.logos = this.generateLogos();
    this.card.errors = {};
    this.card.errors["card-number"] = "Please enter a valid card number";
    this.card.errors["expiry-date"] = "Please enter a valid expiry date";
    this.card.errors["cvv"] = "Please enter a valid cvv code";

    const Frames = window.Frames;
    Frames.init("pk_c6437441-5b97-4116-a579-9d3e69ee24aa");
    Frames.addEventHandler(
      Frames.Events.FRAME_VALIDATION_CHANGED,
      this.onValidationChanged
    );
    Frames.addEventHandler(
      Frames.Events.CARD_VALIDATION_CHANGED,
      this.cardValidationChanged
    );

    Frames.addEventHandler(
      Frames.Events.CARD_TOKENIZATION_FAILED,
      this.onCardTokenizationFailed
    );

    Frames.addEventHandler(
      Frames.Events.PAYMENT_METHOD_CHANGED,
      this.paymentMethodChanged
    );

    Frames.addEventHandler(Frames.Events.CARD_TOKENIZED, this.onCardTokenized);
  },
  methods: {
    ////////////////////
    // credit card
    generateLogos() {
      let logos = {};
      logos["card-number"] = {
        src: "card",
        alt: "card number logo",
      };
      logos["expiry-date"] = {
        src: "exp-date",
        alt: "expiry date logo",
      };
      logos["cvv"] = {
        src: "cvv",
        alt: "cvv logo",
      };
      return logos;
    },
    onValidationChanged(event) {
      let e = event.element;

      if (event.isValid || event.isEmpty) {
        if (e === "card-number" && !event.isEmpty) {
          this.showPaymentMethodIcon();
        }
        this.setDefaultIcon(e);
        this.clearErrorIcon(e);
        this.clearErrorMessage(e);
      } else {
        if (e === "card-number") {
          this.clearPaymentMethodIcon();
        }
        this.setDefaultErrorIcon(e);
        this.setErrorIcon(e);
        this.setErrorMessage(e);
      }
    },
    clearErrorMessage(el) {
      let selector = ".error-message__" + el;
      let message = document.querySelector(selector);
      message.textContent = "";
    },
    clearErrorIcon(el) {
      let logo = document.getElementById("icon-" + el + "-error");
      logo.style.removeProperty("display");
    },
    showPaymentMethodIcon(parent, pm) {
      if (parent) parent.classList.add("show");

      let logo = document.getElementById("logo-payment-method");
      if (pm) {
        var name = pm.toLowerCase();
        logo.setAttribute("src", "images/card-icons/" + name + ".svg");
        logo.setAttribute("alt", pm || "payment method");
      }
      logo.style.removeProperty("display");
    },
    clearPaymentMethodIcon(parent) {
      if (parent) parent.classList.remove("show");

      var logo = document.getElementById("logo-payment-method");
      logo.style.setProperty("display", "none");
    },
    setErrorMessage(el) {
      var selector = ".error-message__" + el;
      var message = document.querySelector(selector);
      message.textContent = this.card.errors[el];
    },
    setDefaultIcon(el) {
      var selector = "icon-" + el;
      var logo = document.getElementById(selector);
      logo.setAttribute(
        "src",
        "images/card-icons/" + this.card.logos[el].src + ".svg"
      );
      logo.setAttribute("alt", this.card.logos[el].alt);
    },
    setDefaultErrorIcon(el) {
      var selector = "icon-" + el;
      var logo = document.getElementById(selector);
      logo.setAttribute(
        "src",
        "images/card-icons/" + this.card.logos[el].src + "-error.svg"
      );
      logo.setAttribute("alt", this.card.logos[el].alt);
    },
    setErrorIcon(el) {
      var logo = document.getElementById("icon-" + el + "-error");
      logo.style.setProperty("display", "block");
    },
    onCardTokenizationFailed(error) {
      console.log("CARD_TOKENIZATION_FAILED: %o", error);
      window.Frames.enableSubmitForm();
    },
    // cardValidationChanged() {
    //   let payButton = this.$refs.payButton;
    //   payButton.disabled = !window.Frames.isCardValid();
    // },
    onCardTokenized(event) {
      console.log(event);
      this.$emit("cardTokenized", event.token);
    },
    paymentMethodChanged(event) {
      var pm = event.paymentMethod;
      let container = document.querySelector(".icon-container.payment-method");

      if (!pm) {
        this.clearPaymentMethodIcon(container);
      } else {
        this.clearErrorIcon("card-number");
        this.showPaymentMethodIcon(container, pm);
      }
    },
  },
};
</script>

<style scoped>
iframe {
  /* This fixes a mobile Safari bug */
  height: 38px !important;
}

#payment-form {
  width: 280px;
  margin: 0 auto;
}

label {
  display: block;
  height: 10px;
  color: #13395e;
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  margin: 8px 0;
}

.date-and-code {
  display: flex;
  margin-bottom: 8px;
}

.date-and-code > div:nth-child(1) {
  width: 55.715%;
}
.date-and-code > div:nth-child(2) {
  width: 45.719%;
}

.input-container {
  position: relative;
  display: flex;
  height: 40px;
}
.icon-container:last-child {
  right: 0;
}
.icon-container.payment-method {
  right: 0;
}

.input-container.card-number {
  margin-bottom: 8px;
}
.input-container.expiry-date {
  margin-right: 4px;
}
.input-container.cvv {
  margin-left: 4px;
}

.card-number-frame {
  padding-left: 40px;
}
.expiry-date-frame {
  padding-left: 40px;
}
.cvv-frame {
  padding-left: 40px;
}

div + button {
  margin-top: 8px;
}

.icon-container {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 26px;
  margin: 0 7px;
}

.icon-container.payment-method {
  transform: translateY(-50%) rotateY(90deg);
  transition: opacity 0.15s ease-out;
  opacity: 0;
  top: 50%;
}

.icon-container.payment-method.show {
  opacity: 1;
  transition: all 0.4s ease-out;
  transform: translateY(-50%) rotateY(0deg);
}

.icon-container.payment-method img {
  width: 100%;
}

[id$="-error"] {
  display: none;
}

.frame {
  opacity: 0;
}

.frame--activated {
  opacity: 1;
  border: solid 1px #13395e;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.2);
}

.frame--activated.frame--focus {
  border: solid 1px #13395e;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.15);
}

.frame--activated.frame--invalid {
  border: solid 1px #d96830;
  box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
}

.error-message {
  display: block;
  color: #c9501c;
  font-size: 0.9rem;
  margin: 8px 0 0 1px;
  font-weight: 300;
}

#pay-button {
  border: none;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  height: 40px;
  width: 100%;
  background-color: #13395e;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:active {
  background-color: #0b2a49;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:hover {
  background-color: #15406b;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:disabled {
  background-color: #697887;
  box-shadow: none;
}

#pay-button:not(:disabled) {
  cursor: pointer;
}

.success-payment-message {
  color: #13395e;
  line-height: 1.4;
}
.token {
  color: #b35e14;
  font-size: 0.9rem;
  font-family: monospace;
}

/**
IE11-targeted optimisations
 */
_:-ms-fullscreen,
:root .icon-container {
  display: block;
}

_:-ms-fullscreen,
:root .icon-container img {
  top: 50%;
  -ms-transform: translateY(-50%);
  position: absolute;
}

_:-ms-fullscreen,
#icon-card-number,
_:-ms-fullscreen,
#icon-expiry-date,
_:-ms-fullscreen,
#icon-cvv {
  left: 7px;
}

#checkout-frames-card-number::-ms-clear {
  display: none;
}
</style>
