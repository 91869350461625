<template>
  <div>
    <!-- ------------------------------ -->
    <!-- general info -->
    <!-- ------------------------------ -->
    <!-- header -->
    <vs-row>
      <vs-col class="text-right">
        <h1>
          <i class="fas fa-wrench ml-2"></i>
          تخصيص الحزمة
        </h1>
        <hr />
      </vs-col>
    </vs-row>

    <!-- content -->
    <vs-row>
      <vs-col class="text-center">
        <h4>معلومات الفرد</h4>
        <!-- <small v-if="people.length > 1">(حزمة لكل شخص )</small>
        <br />
        <vs-avatar
          @click="removePerson()"
          v-if="people.length > 1"
          size="large"
          icon="remove"
          color="#ccc"
        />
        <vs-avatar
          v-for="(item, index) in people"
          :key="index + 1"
          @click="selectedPersonIndex = index"
          size="large"
          :color="avatarColor(item, index)"
        />
        <vs-avatar @click="addPerson()" size="large" icon="add" color="#ccc" /> -->
      </vs-col>
    </vs-row>
    <vs-row
      vs-align="center"
      vs-type="flex"
      vs-justify="space-around"
      vs-w="12"
    >
      <vs-col
        class="mt-3"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="4"
        vs-xs="6"
      >
        <vs-input-number v-model="age" label="العمر*" />
      </vs-col>
      <vs-col
        class="mt-3"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="4"
        vs-xs="6"
      >
        <vs-input-number
          :danger="error.people[selectedPersonIndex].height != ''"
          :danger-text="error.people[selectedPersonIndex].height"
          @blur="clearErrors('people', selectedPersonIndex, 'height')"
          v-model="height"
          label="الطول*"
        />
      </vs-col>
      <vs-col
        class="mt-3"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="4"
        vs-xs="6"
      >
        <vs-input-number
          :danger="error.people[selectedPersonIndex].weight != ''"
          :danger-text="error.people[selectedPersonIndex].weight"
          @blur="clearErrors('people', selectedPersonIndex, 'weight')"
          v-model="weight"
          label="الوزن*"
        />
      </vs-col>
      <vs-col
        class="mt-3"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
        vs-xs="12"
      >
        <div
          class="vs-component vs-con-input-label vs-input w-100 vs-input-primary "
          :class="
            error.people[selectedPersonIndex].gender
              ? 'input-icon-validate-danger'
              : ''
          "
        >
          <label for="" class="vs-input--label">الجنس*</label>
          <v-select
            :options="genderOptions"
            :reduce="gender => gender.value"
            label="text"
            class="w-100 ob-select"
            :class="error.people[selectedPersonIndex].gender ? 'has-error' : ''"
            v-model="gender"
            @blur="clearErrors('people', selectedPersonIndex, 'gender')"
            dir="rtl"
          >
          </v-select>
          <span v-if="error.people[selectedPersonIndex].gender"
            ><div
              class="con-text-validation span-text-validation-danger v-enter-to"
              style="height: 22px;"
            >
              <span class="span-text-validation">
                {{ error.people[selectedPersonIndex].gender }}
              </span>
            </div></span
          >
        </div>
        <!-- <vs-select
          :danger="error.people[selectedPersonIndex].gender != ''"
          :danger-text="error.people[selectedPersonIndex].gender"
          @blur="clearErrors('people', selectedPersonIndex, 'gender')"
          class="selectExample w-100"
          label="الجنس*"
          v-model="gender"
        >
          <vs-select-item value="male" text="ذكر" />
          <vs-select-item value="female" text="أنثى" />
        </vs-select> -->
      </vs-col>
      <vs-col
        class="mt-3"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
        vs-xs="12"
      >
        <div
          class="vs-component vs-con-input-label vs-input w-100 vs-input-primary "
          :class="
            error.people[selectedPersonIndex].reasonToJoin
              ? 'input-icon-validate-danger'
              : ''
          "
        >
          <label for="" class="vs-input--label">سبب الإشتراك*</label>
          <v-select
            :options="reasonToJoinOptions"
            :reduce="reasonToJoin => reasonToJoin.value"
            label="text"
            class="w-100 ob-select "
            :class="
              error.people[selectedPersonIndex].reasonToJoin ? 'has-error' : ''
            "
            v-model="reasonToJoin"
            @blur="clearErrors('people', selectedPersonIndex, 'reasonToJoin')"
            dir="rtl"
          >
          </v-select>
          <span v-if="error.people[selectedPersonIndex].reasonToJoin"
            ><div
              class="con-text-validation span-text-validation-danger v-enter-to"
              style="height: 22px;"
            >
              <span class="span-text-validation">
                {{ error.people[selectedPersonIndex].reasonToJoin }}
              </span>
            </div></span
          >
        </div>
        <!-- <vs-select
          :danger="error.people[selectedPersonIndex].reasonToJoin != ''"
          :danger-text="error.people[selectedPersonIndex].reasonToJoin"
          @blur="clearErrors('people', selectedPersonIndex, 'reasonToJoin ')"
          class="selectExample w-100"
          label="سبب الإشتراك*"
          v-model="reasonToJoin"
        >
          <vs-select-item value="lose-wieght" text="خسارة الوزن" />
          <vs-select-item value="gain-wieght" text="زيادة الوزن" />
          <vs-select-item
            value="save-cooking-time"
            text="استثمار وقت إعداد الطعام بِئُمور اخرى"
          />
        </vs-select> -->
      </vs-col>
      <vs-col
        class="mt-3 "
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="3"
        vs-xs="12"
      >
        <vs-input label="حساسية" v-model="allergies" class="w-100" />
      </vs-col>
    </vs-row>

    <vs-row vs-align="center" vs-justify="space-around" vs-w="12" class="mt-3">
      <vs-col
        type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="5"
        vs-xs="12"
      >
        <vs-card actionable class="cardx">
          <div slot="media" class="mt-5">
            <img src="@/assets/images/make-your-meal.png" class="ob-img" />
          </div>
          <div>
            <h1 class="text-center">أصنع وجبتك بنفسك</h1>
            <span>*يتم حساب السعر بناءً على الوجبات المختارة</span>
          </div>
          <div slot="footer">
            <vs-row
              vs-align="center"
              vs-type="flex"
              vs-justify="space-around"
              vs-w="12"
            >
              <vs-button
                :color="selectedMethodColor('customized-meal')"
                type="filled"
                @click="setMealMethod('customized-meal')"
              >
                <i class="fas fa-check-circle"></i>
                {{
                  selectedMealMethod == "customized-meal"
                    ? "تم الإختيار"
                    : "أختر الأن"
                }}
              </vs-button>
            </vs-row>
          </div>
        </vs-card>
      </vs-col>
      <vs-col
        type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="5"
        vs-xs="12"
      >
        <vs-card actionable class="cardx">
          <div slot="media" class="mt-5">
            <img src="@/assets/images/made-by-dawsat.png" class="ob-img" />
          </div>
          <div>
            <h1 class="text-center">وجبات دوسات الجاهزة</h1>
            <span class="ob-main-card-price">{{ `${mealPrice}` }} </span>
          </div>
          <div slot="footer">
            <vs-row
              vs-align="center"
              vs-type="flex"
              vs-justify="space-around"
              vs-w="12"
            >
              <vs-button
                :color="selectedMethodColor('full-meal')"
                type="filled"
                @click="setMealMethod('full-meal')"
              >
                <i class="fas fa-check-circle"></i>
                {{
                  selectedMealMethod == "full-meal"
                    ? "تم الإختيار"
                    : "أختر الأن"
                }}
              </vs-button>
            </vs-row>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
    <small class="text-danger" v-if="error.peopleMessage">
      {{ error.people[selectedPersonIndex].mealMethod }}
    </small>
    <hr />
    <div v-if="selectedMealMethod">
      <vs-row
        vs-align="center"
        vs-type="flex"
        vs-justify="space-around"
        vs-w="12"
      >
        <vs-col class="mt-3" vs-justify="center" vs-align="center">
          <h5>
            قم بإختيار الوجبة/الوجبات التي تريد استلامها و ملحقاتها في كل يوم من
            ايام الإشتراك
          </h5>
        </vs-col>
      </vs-row>

      <vs-row
        vs-align="center"
        vs-justify="center"
        v-if="$store.state.packageInfo.duration != 'Week'"
      >
        <vs-col
          class="mt-3"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="4"
          vs-xs="12"
        >
          <v-select
            :options="chooseWeekOptions"
            :reduce="chooseWeekOptions => chooseWeekOptions.value"
            label="text"
            class="w-100 ob-select "
            :class="
              error.people[selectedPersonIndex].chooseWeekOptions
                ? 'has-error'
                : ''
            "
            v-model="selectedWeek"
            @blur="
              clearErrors('people', selectedPersonIndex, 'chooseWeekOptions')
            "
            dir="rtl"
          >
          </v-select>
        </vs-col>
        <vs-col
          class="mt-3"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="4"
          vs-xs="12"
        >
          <vs-button
            color="dark"
            type="line"
            icon="file_copy"
            @click="copySelectedWeek()"
            >أنسخ هذا الأسبوع الى جميع الأسابيع</vs-button
          >
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col>
          <div class="ob-dayRow">
            <div v-for="(day, index) in deliveryDays" :key="day.text">
              <vs-button
                class="m-1"
                :class="
                  selectedDayIndex == index ? 'ob-border-bottom-primary' : ''
                "
                :color="daysColor(index)"
                size="large"
                @click="selectedDayIndex = index"
              >
                {{ day.text }}
              </vs-button>
              <br />
              <small>{{
                addWeeks(day.date, selectedWeek - 1).getDate() +
                  "-" +
                  (addWeeks(day.date, selectedWeek - 1).getMonth() + 1)
              }}</small>
            </div>
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col
          vs-justify="center"
          vs-align="center"
          :vs-w="selectedPackage != 1 ? 2 : 12"
          :vs-xs="selectedPackage != 1 ? 4 : 12"
        >
          <img
            src="@/assets/images/package-1.png"
            alt="وجبة 1"
            class="mb-3 wm-100"
          />
          <br />
          <small
            class="text-white mt-3"
            :class="mealColor('lunch')"
            @click="selectedMeal = 'lunch'"
            style="border-radius:.3rem;padding:5px;cursor:pointer;"
            >وجبة الغداء</small
          >
        </vs-col>
        <vs-col
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          vs-xs="4"
          v-if="selectedPackage != 1"
        >
          <img
            src="@/assets/images/package-2.png"
            alt="وجبة 1"
            class="mb-3 wm-100"
          />
          <br />
          <small
            class="text-white mt-3"
            :class="mealColor('dawnSnack')"
            @click="selectedMeal = 'dawnSnack'"
            style="border-radius:.3rem;padding:5px;cursor:pointer;"
            >وجبة 1</small
          >
        </vs-col>
        <vs-col
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          vs-xs="4"
          v-if="selectedPackage != 1"
        >
          <img
            src="@/assets/images/package-3.png"
            alt="وجبة 1"
            class="mb-3 wm-100"
          />
          <br />
          <small
            class="text-white mt-3"
            :class="mealColor('breakfast')"
            @click="selectedMeal = 'breakfast'"
            style="border-radius:.3rem;padding:5px;cursor:pointer;"
            >وجبة 1</small
          >
        </vs-col>
        <vs-col
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          vs-xs="4"
          v-if="selectedPackage != 1"
        >
          <img
            src="@/assets/images/package-4.png"
            alt="وجبة 1"
            class="mb-3 wm-100"
          />
          <br />
          <small
            class="text-white mt-3"
            :class="mealColor('morningSnack')"
            @click="selectedMeal = 'morningSnack'"
            style="border-radius:.3rem;padding:5px;cursor:pointer;"
            >وجبة 1</small
          >
        </vs-col>
        <vs-col
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          vs-xs="4"
          v-if="selectedPackage != 1"
        >
          <img
            src="@/assets/images/package-5.png"
            alt="وجبة 1"
            class="mb-3 wm-100"
          />
          <br />
          <small
            class="text-white mt-3"
            :class="mealColor('dinner')"
            @click="selectedMeal = 'dinner'"
            style="border-radius:.3rem;padding:5px;cursor:pointer;"
            >وجبة 1</small
          >
        </vs-col>
        <vs-col
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          vs-xs="4"
          v-if="selectedPackage != 1"
        >
          <img
            src="@/assets/images/package-6.png"
            alt="وجبة 1"
            class="mb-3 wm-100"
          />
          <br />
          <small
            class="text-white mt-3"
            :class="mealColor('nighttimeSnack')"
            @click="selectedMeal = 'nighttimeSnack'"
            style="border-radius:.3rem;padding:5px;cursor:pointer;"
            >وجبة 1</small
          >
        </vs-col>
      </vs-row>
      <vs-row class="mt-4">
        <vs-col type="flex" vs-justify="center" vs-align="center">
          <vs-checkbox v-model="noMeal" class="m-4"
            >لا اريد استلام هذه الوجبة</vs-checkbox
          >
          <div v-if="!noMeal">
            <vs-row
              v-if="selectedMealMethod == 'full-meal'"
              vs-justify="center"
              vs-align="center"
            >
              <vs-col>
                <menu-card
                  v-for="meal in selectedDayFullMeals"
                  :key="meal.id"
                  :mealId="meal.id"
                  :images="meal.fields['Food Image']"
                  :title="meal.fields['Arabic Text for Website']"
                  :englishName="
                    meal.fields[
                      'Dish Name (from Food Items & Costs)'
                    ].toString()
                  "
                  :ingredients="meal.fields['# Dawsat']"
                  :dayIndex="(selectedWeek - 1) * 7 + selectedDayIndex"
                  :price="meal.fields['Minimum Customer Price for Full Meal']"
                  :selectedMeal="selectedMeal"
                  :personIndex="selectedPersonIndex"
                  :date="
                    addWeeks(
                      deliveryDays[selectedDayIndex].date,
                      selectedWeek - 1
                    )
                  "
                ></menu-card>
              </vs-col>
              <vs-popup
                class="holamundo"
                title="إختر وجبة الحلاة التي تريدها"
                :active.sync="popupActive"
              >
                <swiper ref="mySwiper" :options="swiperOptions">
                  <swiper-slide
                    v-for="meal in selectedDayDessert"
                    :key="meal.id"
                    class="ob-wm-300"
                  >
                    <small-menu-card
                      :mealId="meal.id"
                      :images="meal.fields['Image']"
                      :title="meal.fields['Arabic Dish Name']"
                      :englishName="meal.fields['Dish Name']"
                      :dawsat="meal.fields['Dawsat']"
                      :dayIndex="(selectedWeek - 1) * 7 + selectedDayIndex"
                      :price="
                        meal.fields['Minimum Customer Price (at 25% Food cost)']
                      "
                      selectedMeal="lunchDessert"
                      :personIndex="selectedPersonIndex"
                      :date="
                        addWeeks(
                          deliveryDays[selectedDayIndex].date,
                          selectedWeek - 1
                        )
                      "
                    ></small-menu-card>
                  </swiper-slide>
                  <div class="swiper-button-prev" slot="button-prev"></div>
                  <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
              </vs-popup>
              <vs-button
                :color="dessertButtonColor()"
                type="line"
                icon="cake"
                @click="popupActive = true"
              >
                {{
                  dessertButtonColor() == "success"
                    ? "قم بتعديل وجبة الحلاة"
                    : "أضف تحلاية الى هذه الوجبة"
                }}</vs-button
              >
            </vs-row>
            <div v-if="selectedMealMethod == 'customized-meal'">
              <!-- main meal -->

              <vs-row class="mt-5">
                <vs-col class="text-center">
                  <h4>الوجبة الرئيسية*</h4>
                </vs-col>
              </vs-row>
              <vs-row>
                <swiper ref="mySwiper" :options="swiperOptions">
                  <swiper-slide
                    v-for="meal in selectedDayMain"
                    :key="meal.id"
                    class="ob-wm-300"
                  >
                    <small-menu-card
                      :mealId="meal.id"
                      :images="meal.fields['Image']"
                      :title="meal.fields['Arabic Dish Name']"
                      :englishName="meal.fields['Dish Name']"
                      :dawsat="meal.fields['Dawsat']"
                      :amount="meal.fields['Dish Quantity/Size']"
                      :dayIndex="(selectedWeek - 1) * 7 + selectedDayIndex"
                      :price="
                        meal.fields['Minimum Customer Price (at 25% Food cost)']
                      "
                      selectedMeal="lunchMain"
                      :personIndex="selectedPersonIndex"
                      :date="
                        addWeeks(
                          deliveryDays[selectedDayIndex].date,
                          selectedWeek - 1
                        )
                      "
                    ></small-menu-card>
                  </swiper-slide>
                  <div class="swiper-button-prev" slot="button-prev"></div>
                  <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
              </vs-row>

              <!-- Side dish -->
              <vs-row class="mt-5">
                <vs-col class="text-center">
                  <h4>طبق جانبي</h4>
                </vs-col>
              </vs-row>
              <vs-row>
                <swiper ref="mySwiper" :options="swiperOptions">
                  <swiper-slide
                    v-for="meal in selectedDaySideDish"
                    :key="meal.id"
                    class="ob-wm-300"
                  >
                    <small-menu-card
                      :mealId="meal.id"
                      :images="meal.fields['Image']"
                      :title="meal.fields['Arabic Dish Name']"
                      :englishName="meal.fields['Dish Name']"
                      :dawsat="meal.fields['Dawsat']"
                      :amount="meal.fields['Dish Quantity/Size']"
                      :dayIndex="(selectedWeek - 1) * 7 + selectedDayIndex"
                      :price="
                        meal.fields['Minimum Customer Price (at 25% Food cost)']
                      "
                      selectedMeal="lunchSideDish"
                      :personIndex="selectedPersonIndex"
                      :date="
                        addWeeks(
                          deliveryDays[selectedDayIndex].date,
                          selectedWeek - 1
                        )
                      "
                    ></small-menu-card>
                  </swiper-slide>
                  <div class="swiper-button-prev" slot="button-prev"></div>
                  <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
              </vs-row>

              <!--  Salad -->
              <vs-row class="mt-5">
                <vs-col class="text-center">
                  <h4>السلطة</h4>
                </vs-col>
              </vs-row>
              <vs-row>
                <swiper ref="mySwiper" :options="swiperOptions">
                  <swiper-slide
                    v-for="meal in selectedDaySalad"
                    :key="meal.id"
                    class="ob-wm-300"
                  >
                    <small-menu-card
                      :mealId="meal.id"
                      :images="meal.fields['Image']"
                      :title="meal.fields['Arabic Dish Name']"
                      :englishName="meal.fields['Dish Name']"
                      :dawsat="meal.fields['Dawsat']"
                      :dayIndex="(selectedWeek - 1) * 7 + selectedDayIndex"
                      :price="
                        meal.fields['Minimum Customer Price (at 25% Food cost)']
                      "
                      selectedMeal="lunchSalad"
                      :personIndex="selectedPersonIndex"
                      :date="
                        addWeeks(
                          deliveryDays[selectedDayIndex].date,
                          selectedWeek - 1
                        )
                      "
                    ></small-menu-card>
                  </swiper-slide>
                  <div class="swiper-button-prev" slot="button-prev"></div>
                  <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
              </vs-row>

              <!-- soup -->
              <vs-row class="mt-5">
                <vs-col class="text-center">
                  <h4>الحساء</h4>
                </vs-col>
              </vs-row>
              <vs-row>
                <swiper ref="mySwiper" :options="swiperOptions">
                  <swiper-slide
                    v-for="meal in selectedDaySoup"
                    :key="meal.id"
                    class="ob-wm-300"
                  >
                    <small-menu-card
                      :mealId="meal.id"
                      :images="meal.fields['Image']"
                      :title="meal.fields['Arabic Dish Name']"
                      :englishName="meal.fields['Dish Name']"
                      :dawsat="meal.fields['Dawsat']"
                      :dayIndex="(selectedWeek - 1) * 7 + selectedDayIndex"
                      :price="
                        meal.fields['Minimum Customer Price (at 25% Food cost)']
                      "
                      selectedMeal="lunchSoup"
                      :personIndex="selectedPersonIndex"
                      :date="
                        addWeeks(
                          deliveryDays[selectedDayIndex].date,
                          selectedWeek - 1
                        )
                      "
                    ></small-menu-card>
                  </swiper-slide>
                  <div class="swiper-button-prev" slot="button-prev"></div>
                  <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
              </vs-row>

              <!--  Carbohydrate -->
              <vs-row class="mt-5">
                <vs-col class="text-center">
                  <h4>الكاربوهايدرات</h4>
                </vs-col>
              </vs-row>
              <vs-row>
                <swiper ref="mySwiper" :options="swiperOptions">
                  <swiper-slide
                    v-for="meal in selectedDayCarbohydrate"
                    :key="meal.id"
                    class="ob-wm-300"
                  >
                    <small-menu-card
                      :mealId="meal.id"
                      :images="meal.fields['Image']"
                      :title="meal.fields['Arabic Dish Name']"
                      :englishName="meal.fields['Dish Name']"
                      :dawsat="meal.fields['Dawsat']"
                      :dayIndex="(selectedWeek - 1) * 7 + selectedDayIndex"
                      :price="
                        meal.fields['Minimum Customer Price (at 25% Food cost)']
                      "
                      selectedMeal="lunchCarbohydrate"
                      :personIndex="selectedPersonIndex"
                      :date="
                        addWeeks(
                          deliveryDays[selectedDayIndex].date,
                          selectedWeek - 1
                        )
                      "
                    ></small-menu-card>
                  </swiper-slide>
                  <div class="swiper-button-prev" slot="button-prev"></div>
                  <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
              </vs-row>

              <!--  desert -->
              <vs-row class="mt-5">
                <vs-col class="text-center">
                  <h4>الحلويات</h4>
                </vs-col>
              </vs-row>
              <vs-row>
                <swiper ref="mySwiper" :options="swiperOptions">
                  <swiper-slide
                    v-for="meal in selectedDayDessert"
                    :key="meal.id"
                    class="ob-wm-300"
                  >
                    <small-menu-card
                      :mealId="meal.id"
                      :images="meal.fields['Image']"
                      :title="meal.fields['Arabic Dish Name']"
                      :englishName="meal.fields['Dish Name']"
                      :dawsat="meal.fields['Dawsat']"
                      :dayIndex="(selectedWeek - 1) * 7 + selectedDayIndex"
                      :price="
                        meal.fields['Minimum Customer Price (at 25% Food cost)']
                      "
                      selectedMeal="lunchDessert"
                      :personIndex="selectedPersonIndex"
                      :date="
                        addWeeks(
                          deliveryDays[selectedDayIndex].date,
                          selectedWeek - 1
                        )
                      "
                    ></small-menu-card>
                  </swiper-slide>
                  <div class="swiper-button-prev" slot="button-prev"></div>
                  <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
              </vs-row>
            </div>
            <vs-row
              vs-align="center"
              vs-type="flex"
              vs-justify="space-around"
              vs-w="12"
            >
              <vs-col class="mt-3" vs-justify="center" vs-align="center">
                <small>
                  * سيتم تحديد الوجبات الإفتراضية من دوسات في حال عدم اختيارك اي
                  وجبة لأي يوم من الأيام المدرجة
                  <br />
                  * بإمكانك تعديل الوجبات لاحقا لكن يجب تعديل الوجبة قبل 72 ساعة
                  من موعد الطلب كحد أدنى
                </small>
              </vs-col>
            </vs-row>
          </div>
        </vs-col>
      </vs-row>
    </div>
    <vs-row vs-align="center" vs-justify="space-around" vs-w="12">
      <p class="text-danger" v-if="error.peopleMessage">
        {{ error.peopleMessage }}
      </p>
      <div v-if="error.dayMessage">
        <p class="text-danger">
          {{ error.dayMessage }}
        </p>
        <small class="text-danger"> الأيام التي لم يتم أدخالها هي :</small>
        <small
          v-for="day in error.people[selectedPersonIndex].days"
          :key="day"
          class="text-danger"
          >{{ `${day + 1} ,` }}</small
        >
        <small class="text-danger"> من الإشتراك</small>
      </div>
    </vs-row>
  </div>
</template>

<script>
// import { mapFields } from "vuex-map-fields";
import MenuCard from "@/components/MenuCard";
import SmallMenuCard from "@/components/SmallMenuCard";
import PERSON from "@/store/constants/person.json";

export default {
  components: {
    MenuCard,
    SmallMenuCard,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    selectedMealMethod: {
      get() {
        return this.people[this.selectedPersonIndex].mealMethod;
      },
      set(value) {
        const payload = {
          value: value,
          index: "mealMethod",
          arrayIndex: this.selectedPersonIndex,
        };
        this.$store.commit("SET_PERSON_DATA", payload);
        this.clearErrors("people", this.selectedPersonIndex, "mealMethod");
      },
    },
    noMeal: {
      get() {
        return this.people[this.selectedPersonIndex].days[
          (this.selectedWeek - 1) * 7 + this.selectedDayIndex
        ].drop;
      },
      set(value) {
        const payload = {
          mealId: value,
          selectedMeal: "drop",
          dayIndex: (this.selectedWeek - 1) * 7 + this.selectedDayIndex,
          personIndex: this.selectedPersonIndex,
        };
        this.$store.commit("SET_PERSON_DAY_INFO", payload);
      },
    },
    selectedDayFullMeals() {
      return this.meals.filter(item => {
        if (
          parseInt(item.fields.Day) ==
            (this.selectedWeek - 1) * 7 + this.selectedDayIndex + 1 &&
          item.fields["Meal Name"] == "Lunch Full Meal"
        ) {
          return true;
        } else {
          false;
        }
      });
    },
    selectedDaySoup() {
      return this.customeMeals.filter(item => {
        if (item.fields["Minimum Customer Price (at 25% Food cost)"] <= 0)
          return false;
        if (item.fields["Food Type"] == "Soup") {
          return true;
        } else {
          false;
        }
      });
    },
    selectedDaySalad() {
      return this.customeMeals.filter(item => {
        if (item.fields["Minimum Customer Price (at 25% Food cost)"] <= 0)
          return false;
        if (item.fields["Food Type"] == "Side Salad") {
          return true;
        } else {
          false;
        }
      });
    },
    selectedDayMain() {
      return this.customeMeals.filter(item => {
        if (item.fields["Minimum Customer Price (at 25% Food cost)"] <= 0)
          return false;
        if (item.fields["Food Type"] == "Lunch Main") {
          return true;
        } else {
          false;
        }
      });
    },
    selectedDaySideDish() {
      return this.customeMeals.filter(item => {
        if (item.fields["Minimum Customer Price (at 25% Food cost)"] <= 0)
          return false;
        if (item.fields["Food Type"] == "Side Dish") {
          return true;
        } else {
          false;
        }
      });
    },
    selectedDayCarbohydrate() {
      return this.customeMeals.filter(item => {
        if (item.fields["Minimum Customer Price (at 25% Food cost)"] <= 0)
          return false;
        if (item.fields["Food Type"] == "Carbohydrate") {
          return true;
        } else {
          false;
        }
      });
    },
    selectedDayDessert() {
      return this.customeMeals.filter(item => {
        if (item.fields["Minimum Customer Price (at 25% Food cost)"] <= 0)
          return false;
        if (item.fields["Food Type"] == "Dessert Snack") {
          return true;
        } else {
          false;
        }
      });
    },
    people() {
      return this.$store.state.people;
    },
    deliveryDays() {
      return this.$store.getters.selectedDeliveryDays;
    },
    selectedPackage() {
      return this.$store.state.packageInfo.selectedPackage;
    },
    age: {
      get() {
        return this.people[this.selectedPersonIndex].age;
      },
      set(value) {
        this.$store.commit("SET_PERSON_DATA", {
          arrayIndex: this.selectedPersonIndex,
          index: "age",
          value,
        });
        this.clearErrors("people", this.selectedPersonIndex, "age");
        this.$store.commit("CLEARE_SECOND_TAB_ERROR", {
          index: "peopleMessage",
        });
      },
    },
    height: {
      get() {
        return this.people[this.selectedPersonIndex].height;
      },
      set(value) {
        this.$store.commit("SET_PERSON_DATA", {
          arrayIndex: this.selectedPersonIndex,
          index: "height",
          value,
        });
        this.clearErrors("people", this.selectedPersonIndex, "height");
        this.$store.commit("CLEARE_SECOND_TAB_ERROR", {
          index: "peopleMessage",
        });
      },
    },
    weight: {
      get() {
        return this.people[this.selectedPersonIndex].weight;
      },
      set(value) {
        this.$store.commit("SET_PERSON_DATA", {
          arrayIndex: this.selectedPersonIndex,
          index: "weight",
          value,
        });
        this.clearErrors("people", this.selectedPersonIndex, "weight");
        this.$store.commit("CLEARE_SECOND_TAB_ERROR", {
          index: "peopleMessage",
        });
      },
    },
    gender: {
      get() {
        return this.people[this.selectedPersonIndex].gender;
      },
      set(value) {
        this.$store.commit("SET_PERSON_DATA", {
          arrayIndex: this.selectedPersonIndex,
          index: "gender",
          value,
        });
        this.clearErrors("people", this.selectedPersonIndex, "gender");
        this.$store.commit("CLEARE_SECOND_TAB_ERROR", {
          index: "peopleMessage",
        });
      },
    },
    reasonToJoin: {
      get() {
        return this.people[this.selectedPersonIndex].reasonToJoin;
      },
      set(value) {
        this.$store.commit("SET_PERSON_DATA", {
          arrayIndex: this.selectedPersonIndex,
          index: "reasonToJoin",
          value,
        });
        this.clearErrors("people", this.selectedPersonIndex, "reasonToJoin");
        this.$store.commit("CLEARE_SECOND_TAB_ERROR", {
          index: "peopleMessage",
        });
      },
    },
    allergies: {
      get() {
        return this.people[this.selectedPersonIndex].allergies;
      },
      set(value) {
        this.$store.commit("SET_PERSON_DATA", {
          arrayIndex: this.selectedPersonIndex,
          index: "allergies",
          value,
        });
        this.clearErrors("people", this.selectedPersonIndex, "allergies");
      },
    },
    error() {
      return this.$store.state.error.secondTab;
    },
    selectedMethodColor() {
      let error = this.error;
      return selectedMealMethod => {
        if (error.people[this.selectedPersonIndex].mealMethod) {
          return "danger";
        }
        return this.selectedMealMethod == selectedMealMethod
          ? "success"
          : "primary";
      };
    },
    mealPrice() {
      let duration = this.$store.state.packageInfo.duration;
      let numberOfDeliveryDays = this.$store.state.packageInfo
        .numberOfDeliveryDays;
      if (duration == "Week") {
        if (numberOfDeliveryDays == "fiveDays") return `${200 / 5} SAR/وجبة`;
        else if (numberOfDeliveryDays == "sevenDays")
          return `${300 / 7} SAR/وجبة`;
      } else if (duration == "Month") {
        if (numberOfDeliveryDays == "fiveDays") return `${900 / 20} SAR/وجبة`;
        else if (numberOfDeliveryDays == "sevenDays")
          return `${999 / 28} SAR/وجبة`;
      }
      return 0;
    },
  },
  data() {
    return {
      genderOptions: [
        {
          value: "male",
          text: "ذكر",
        },
        {
          value: "female",
          text: "أنثى",
        },
      ],
      reasonToJoinOptions: [
        {
          value: "lose-wieght",
          text: "خسارة الوزن",
        },
        {
          value: "gain-wieght",
          text: "زيادة الوزن",
        },
        {
          value: "save-cooking-time",
          text: "استثمار وقت   بأمور اخرى",
        },
      ],
      chooseWeekOptions: [
        { value: 1, text: "الأسبوع الأول" },
        { value: 2, text: "الأسبوع الثاني" },
        { value: 3, text: "الأسبوع الثالث" },
        { value: 4, text: "الأسبوع الرابع" },
      ],
      popupActive: false,
      swiperOptions: {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        centerInsufficientSlides: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      selectedPersonIndex: 0,
      selectedDayIndex: 0,
      selectedMeal: "lunch",
      selectedWeek: 1,
      meals: [],
      customeMeals: [],
    };
  },
  methods: {
    copySelectedWeek() {
      this.$store.commit("COPY_SELECTED_WEEK", {
        selectedWeek: this.selectedWeek - 1,
        personIndex: this.selectedPersonIndex,
        weekLength: this.$store.getters.selectedDeliveryDays.length,
      });
    },
    addWeeks(date, numberOfWeeks) {
      let tempDate = new Date(date.getTime());
      tempDate.setDate(tempDate.getDate() + numberOfWeeks * 7);
      return tempDate;
    },
    setMealMethod(selectedMethod) {
      this.selectedMealMethod = selectedMethod;
      const payload = {
        selectedMeal: this.selectedMeal,
        dayIndex: (this.selectedWeek - 1) * 7 + this.selectedDayIndex,
        personIndex: this.selectedPersonIndex,
      };
      if (selectedMethod == "full-meal") {
        this.$store.commit("CLEARE_CUSTMIZED_MEALS", payload);
      } else {
        this.$store.commit("CLEARE_FULL_MEAL", payload);
      }
    },
    removePerson() {
      this.$store.commit("REMOVE_PERSON");
    },
    checkItem(item) {
      if (
        item.age &&
        item.reasonToJoin &&
        item.weight &&
        item.height &&
        item.gender
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkLunch(day) {
      if (
        // day.lunchCarbohydrate.id &&
        // day.lunchSalad.id &&
        // day.lunchDessert.id &&
        // day.lunchSoup.id&&
        day.lunchMain.id
      ) {
        return true;
      }
      if (day.lunch.id) {
        return true;
      }
      return false;
    },
    checkDay(day) {
      if (this.selectedPackage == 1) {
        return this.checkLunch(day);
      } else {
        if (
          // day.dawnSnack &&
          // day.breakfast &&
          // day.morningSnack &&
          // day.dinner &&
          // day.nighttimeSnack
          this.checkLunch(day)
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    checkMeal(mealName) {
      let day = this.people[this.selectedPersonIndex].days[
        (this.selectedWeek - 1) * 7 + this.selectedDayIndex
      ];
      if (day[mealName].id) {
        return true;
      } else {
        return false;
      }
    },
    avatarColor(item, index) {
      let personError = this.error.people[index];
      if (
        personError.age ||
        personError.reasonToJoin ||
        personError.weight ||
        personError.height ||
        personError.mealMethod ||
        personError.gender
      ) {
        return "danger";
      }
      if (this.checkItem(item)) {
        return "success";
      }
      if (index == this.selectedPersonIndex) {
        return "primary";
      }
      return "#cccccc";
    },
    daysColor(index) {
      let day = this.people[this.selectedPersonIndex].days[
        (this.selectedWeek - 1) * 7 + index
      ];
      if (this.error.people[this.selectedPersonIndex].days.includes(index))
        return "danger";
      if (day.drop) return "orange";

      if (this.checkDay(day)) {
        return "success";
      }
      if (index == this.selectedDayIndex) {
        return "primary";
      }
      return "#cccccc";
    },
    dessertButtonColor() {
      let day = this.people[this.selectedPersonIndex].days[
        (this.selectedWeek - 1) * 7 + this.selectedDayIndex
      ];
      if (day["lunchDessert"].id) {
        return "success";
      } else {
        return "#25b0d0";
      }
    },
    mealColor(mealName) {
      if (this.noMeal) return "ob-bg-orange";
      if (this.selectedMealMethod == "customized-meal") {
        if (this.checkMeal("lunchMain")) {
          return "ob-bg-success";
        }
        if (this.selectedMeal == mealName) {
          return "ob-bg-primary";
        }
        return "ob-bg-ccc";
      } else {
        if (this.checkMeal("lunch")) {
          return "ob-bg-success";
        }
        if (this.selectedMeal == mealName) {
          return "ob-bg-primary";
        }
        return "ob-bg-ccc";
      }
    },
    addPerson() {
      const tempPerson = Object.assign({}, PERSON);
      this.$store.commit("ADD_PERSON", tempPerson);
    },
    clearErrors(index, selected, peopleKey) {
      this.$store.dispatch("clearErrors", {
        index,
        selected,
        peopleKey,
      });
    },
  },
  mounted() {
    // this.$vs.loading();
    this.$http
      .get("/api/v2/kitopi/lunch-full-meals")
      .then(response => {
        // this.$vs.loading.close();

        this.meals = response.data.records;
      })
      .catch(error => {
        if (!this.errorOccured) {
          this.errorOccured = true;
          // this.$vs.loading.close();
          console.log(error.response);
          if (
            !(
              error.response &&
              error.response.data &&
              error.response.data.message
            )
          ) {
            this.errorMessage = "Server error occured please try again later";
          }
          alert(this.errorMessage);
          this.$router.push("/");
        }
      });

    this.$http
      .get("/api/v2/kitopi/lunch-customized-meals")
      .then(response => {
        // this.$vs.loading.close();

        this.customeMeals = response.data.records;
      })
      .catch(error => {
        if (!this.errorOccured) {
          this.errorOccured = true;
          // this.$vs.loading.close();
          console.log(error.response);
          if (
            !(
              error.response &&
              error.response.data &&
              error.response.data.message
            )
          ) {
            this.errorMessage = "Server error occured please try again later";
          }
          alert(this.errorMessage);
          this.$router.push("/");
        }
      });
  },
};
</script>
<style>
.ob-dayRow {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.ob-bg-success {
  background: #46c93a;
}
.ob-bg-primary {
  background: #6d5544;
}
.ob-bg-orange {
  background: orange;
}
.ob-bg-ccc {
  background: #ccc;
}
.wm-100 {
  max-width: 100px;
}
.ob-wm-300 {
  max-width: 300px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  min-width: 33%;
}
.ob-main-card-price {
  font-size: 1.8rem;
  border-width: 0px 0px 4px 0px;
  border-color: #20889f;
  border-style: solid;
  border-radius: 0.3rem;
  padding: 5px;
  background-color: #25b0d0;
  color: #fff;
}
.ob-border-bottom-primary {
  border-width: 0px 0px 4px 0px;
  border-color: #6d5544;
  border-style: solid;
}
</style>
